import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("connect");
    this.audioChunks = []; // record audio here
    this.initializeMediaRecorder();
  }

  // Init the record
  initializeMediaRecorder() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false }) // if user accept the request
      .then(stream => {
        this.mediaRecorder = new MediaRecorder(stream, {mimeType: 'audio/webm'});
        this.mediaRecorder.addEventListener("dataavailable", event => { this.audioChunks.push(event.data); });
        
        this.mediaRecorder.addEventListener("stop", () => this.stopRecorder());
      });
  }
  stopRecorder() {
    const audioBlob = new Blob(this.audioChunks, { type: "audio/webm"});
    this.audioChunks.length = 0;

    const audio = new Audio();
    audio.controls = true;
    audio.src = URL.createObjectURL(audioBlob);

    const recorderBox = this.element.querySelector("#recorder-box");
    recorderBox.innerHTML = "";
    recorderBox.appendChild(audio);

    recorderBox.insertAdjacentHTML(
      "afterend",
      '<div id="spinner" class="d-flex jsutify-content-center"><div class="spinner-grow" role="status"></div></div>'
    );

    const formData = new FormData();
    formData.append("files", audioBlob);

    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    fetch("post_audio", {
      method: "POST",
      headers: { "X-CSRF-Token": csrf },
      body: formData
    }).then(response => response.json())
    .then(data => {
      const paragraphs = recorderBox.querySelectorAll('p');
      paragraphs.forEach(p => {p.remove(); });

      const spinner = this.element.querySelector('#spinner');
      spinner.remove();

      recorderBox.insertAdjacentHTML("beforeend", `<p id="new-idea" class="bg-white rounded mt-3">${data.text}</p>`);
    });
  }

  toggleRecorder() {
    const btnElement = this.element.querySelector('#buttonStart');
    //const micElement = this.element.querySelector('#mic-toggle');

    if (this.mediaRecorder.state == "inactive") {
      this.mediaRecorder.start();
      btnElement.classList.remove('bg-cyan-600','hover:bg-cyan-700');
      btnElement.classList.add('bg-red-600','hover:bg-red-700');
      //btnElement.style.color = "FireBrick";
      //micElement.style.color = "FireBrick";
    } else {
      this.mediaRecorder.stop();
      btnElement.classList.remove('bg-red-600','hover:bg-red-700');
      btnElement.classList.add('bg-cyan-600','hover:bg-cyan-700');
      //btnElement.style.color = "black";
      //micElement.style.color = "black";
    }
  }

  saveIdea() {
    const recorderBox = this.element.querySelector('#recorder-box');
    const newIdea = recorderBox.querySelector('#new-idea');
    let txt = "";

    if (newIdea === null) {
      txt = "";
    } else {
      txt = newIdea.innerText;
    }
  }
}
