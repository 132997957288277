// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"
import { Dropdown, Toggle, Modal } from "tailwindcss-stimulus-components"
import RailsNestedForm from '@stimulus-components/rails-nested-form'
import RecorderController from "./recorder_controller"

application.register("recorder", RecorderController)
application.register('modal', Modal)
application.register('dropdown', Dropdown)
application.register('toggle', Toggle)
application.register('nested-form', RailsNestedForm)
// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
